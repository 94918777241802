<template>
  <div id="med-list">
    <div id="wasted-med">
      <h4>Report med waste here</h4>
      <v-combobox
        v-model="wastedMed"
        :items="medList"
        item-text="display"
        outlined
        label="Select Wasted Med"
        clearable
        @change="wastedMedSelect(wastedMed)"
      >
      </v-combobox>

      <v-text-field
        v-model="wastedDose"
        outlined
        label="How many doses did you waste"
        placeholder="1.5"
        hide-details
        class="mb-3"
      ></v-text-field>

      <v-text-field
        v-model="wastedReason"
        outlined
        label="Reason for Waste"
        placeholder="Dity Needle"
        hide-details
        class="mb-3"
      ></v-text-field>

      <v-btn
        color="primary"
        text
        @click="submitWaste"
      >
        Submit
      </v-btn>
      <hr />
    </div>

    <!-- dialog -->

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchData"
    ></delete-confirmation>

    <v-dialog
      v-model="isMedicationFormActive"
      persistent
      max-width="75%"
    >
      <div>
        <v-card>
          <medication-add-edit
            v-if="isMedicationFormActive"
            :is-medication-form-active="isMedicationFormActive"
            :is-edit="isEdit"
            :patient-script-d-t-o="selectedItem"
            @update-dialog="updateDialog"
            @refetch-data="fetchData"
          ></medication-add-edit>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
      v-model="isWasteFormActive"
      persistent
      max-width="75%"
    >
      <div>
        <v-card>
          <wasted-form
            v-if="isWasteFormActive"
            :is-medication-form-active="isWasteFormActive"
            :is-edit="isEdit"
            :script-id="ssdfs"
            :patient-script-d-t-o="selectedItem"
            @update-dialog="updateDialog"
            @refetch-data="fetchData"
          ></wasted-form>
        </v-card>
      </div>
    </v-dialog>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchScript"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="allergy-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedScript"
        :headers="scriptTableColumns"
        :items="scriptList"
        :options.sync="options"
        :search="searchScript"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >
        <template #[`item.distributionSchedule.startDate`]="{item}">
          {{ formatDate(item.distributionSchedule.startDate) }}
        </template>
        <template #[`item.distributionSchedule.stopDate`]="{item}">
          {{ formatDate(item.distributionSchedule.stopDate) }}
        </template>
        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isMedicationFormActive = !isMedicationFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isMedicationFormActive = !isMedicationFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item
                link
              >
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>{{ item.isActive ? 'Disable' : 'Activate' }}</span>
                </v-btn>
              </v-list-item>
              <v-list-item
                v-if="item.Refills > 0"
                link
              >
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>{{ item.isActive ? 'Disable' : 'Activate' }}</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiRefreshCircle }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="refillMed(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiRefreshCircle,
} from '@mdi/js'

import medicationService from '@/services/MedicationService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useMedicationList from '@/store/useMedicationService'
import CommonList from '@/store/CommonService'
import MedicationAddEdit from './MedicationAddEdit.vue'

export default {
  components: { DeleteConfirmation, MedicationAddEdit },
  data() {
    return {
      wastedMed: null,
      medList: [],
      wastedDose: 0,
      wasteReason: '',
    }
  },
  setup() {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const {
      scriptList,
      scriptTableColumns,
      searchScript,
      statusFilter,
      loading,
      options,
      selectedScript,
      refillThisMed,
      reportWaste,

      fetchSiteScripts,
    } = useMedicationList()

    const { readStatus, formatDate } = CommonList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isMedicationFormActive = ref(false)
    const isWasteFormActive = ref(false)

    return {
      scriptList,
      scriptTableColumns,
      searchScript,
      statusFilter,
      loading,
      options,
      isMedicationFormActive,
      isWasteFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      selectedScript,
      readStatus,
      formatDate,
      refillThisMed,
      reportWaste,

      fetchSiteScripts,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiRefreshCircle,
      },
    }
  },
  mounted() {
    this.fetchSiteScripts()

    // console.log(this.scriptList)
  },
  methods: {
    fetchData() {
      this.fetchSiteScripts()
      this.$watch('scriptList', () => {
        // console.log(this.scriptList)
        this.medList = this.scriptList
      })
    },
    wastedMedSelect(med) {
      console.log(`begin med waste: ${med.Id}`)
      this.reportWaste(med.id)
    },
    submitWaste() {
      const DTO = {
        patientScriptId: this.wastedMed.id,
        amount: this.wastedDose,
        reason: this.wasteReason,
      }
      this.reportWaste(DTO)
    },
    refillMed(med) {
      if (confirm('Are you that you want to refill this medication?')) {
        // use the prescription id
        const result = this.refillThisMed(med)
        alert(`Refilled = ${result}`)
      }
    },
    refillStockMed(med) {
      if (confirm('Are you that you want to refill this medication?')) {
        // use the prescription id
        const result = this.refillStockMed(med)
        alert(`Refilled = ${result}`)
      }
    },
    submitDelete(med) {
      // console.log(med)
    },
    updateDialog(status) {
      // console.log('Status', status)
      // console.log('Dialog', this.isMedicationFormActive)

      /* if (status == null) {
        this.selectedItem = {}
      }
      */
      this.isMedicationFormActive = !this.isMedicationFormActive

      // console.log('Selected Item', this.selectedItem)
    },
  },
}
</script>
